
import { Vue, Component } from "vue-property-decorator";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { EmpreendimentoService } from "@/core/services/cadastros";

@Component
export default class CfgDespesaRateada extends Vue {
  item: {
    empreendimentoId: string;
    empresaId: string;
    dataInicioEmissao: string;
    dataFimEmissao: string; 
  } = {
    empreendimentoId: "",
    empresaId: "",
    dataInicioEmissao: "",
    dataFimEmissao: "",
  };

  valid = true;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  empresas = [];
  empreendimentos = [];
  

  mounted() {
    const empresaService = new EmpresaService();
    empresaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      (res) => {
        this.empresas = res.data.items;
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );

    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items;
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }  

  Visualizar() {
      if((!this.item.dataInicioEmissao || !this.item.dataFimEmissao)){
          this.$swal("Aviso", "Obrigatório preencher início e fim da data.", "warning");
          return true;
      }    
    let routeLink = this.$router.resolve({name: 'relDespesaRateada',
      query:{
        empreendimentoId: this.item.empreendimentoId ? this.item.empreendimentoId: "",
        empresaId: this.item.empresaId ? this.item.empresaId: "",
        dataInicioEmissao: this.item.dataInicioEmissao ? this.item.dataInicioEmissao: "",
        dataFimEmissao: this.item.dataFimEmissao ? this.item.dataFimEmissao: "",
      }
    }); 
    window.open(routeLink.href, '_blank');
  }
}
